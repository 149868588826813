import React from "react";
import { AiFillHome, AiFillContacts, AiFillClockCircle } from "react-icons/ai";
import emailjs from "emailjs-com";

const juneImage = new URL("/src/assets/junedetail.jpg", import.meta.url);
const sendEmail = (prop) =>
  emailjs
    .sendForm("service_v7f7rrr", "template_tizjc6h", prop.target)
    .then((res) => console.log(res.text))
    .catch((err) => console.log(err));

const Contact = () => {
  return (
    <>
      <img
        src={juneImage}
        alt="june baththube"
        className="fixed mt-[-75px] w-screen -z-10"
      />
      <div className="pt-16 pb-10 sm:pt-16 sm:pb-16 lg:pb-16 md:h-[92vh]">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-10 bg-stone-600/40 rounded-3xl">
          <div class="mb-4">
            <div class="mb-6 max-w-3xl text-left sm:text-center md:mx-auto ">
              <h2 class="text-3xl font-bold leading-tight gray-primary-color sm:text-4xl lg:text-5xl">
                Stuur ons een bericht
              </h2>
            </div>
          </div>

          <div class="flex justify-center">
            <div class="grid md:grid-cols-2">
              <div class="my-auto pr-6">
                <ul>
                  <li class="flex">
                    <AiFillHome className="w-[70px] h-auto text-white" />
                    <div class="m-5">
                      <h3 class="text-lg font-bold text-white">Adres</h3>
                      <p class="text-white">Laar 191</p>
                      <p class="text-white">2180 Ekeren</p>
                    </div>
                  </li>
                  <li class="flex">
                    <AiFillContacts className="w-[70px] h-auto text-white" />
                    <div class="m-5">
                      <h3 class="text-lg font-bold text-white">Contact</h3>
                      <p class="text-white">Mobiel: +32 (0) 485 270 907</p>
                      <p class="text-white">Email: info@vett.be</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="max-w-6xl p-5 md:p-12" id="form">
                <h2 class="mb-4 text-2xl font-bold text-white">Uw gegevens</h2>
                <form
                  action="https://getform.io/f/zbqO1Gep"
                  method="POST"
                  onSubmit={sendEmail}
                >
                  <div class="mb-6">
                    <div class="mx-0 mb-1 sm:mb-4">
                      <div class="mx-0 mb-1 sm:mb-4">
                        <input
                          type="text"
                          id="name"
                          autocomplete="given-name"
                          placeholder="uw naam"
                          class="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 sm:mb-0"
                          name="name"
                        />
                      </div>
                      <div class="mx-0 mb-1 sm:mb-4">
                        <input
                          type="email"
                          id="email"
                          autocomplete="email"
                          placeholder="uw email adres"
                          class="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 sm:mb-0"
                          name="email"
                        />
                      </div>
                    </div>
                    <div class="mx-0 mb-1 sm:mb-4">
                      <textarea
                        id="textarea"
                        name="message"
                        cols="30"
                        rows="5"
                        placeholder="schrijf hier uw bericht"
                        class="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4sm:mb-0"
                      ></textarea>
                    </div>
                  </div>
                  <div class="text-center">
                    <button
                      type="submit"
                      class="w-full bg-primary-color text-white px-6 py-3 font-xl rounded-md sm:mb-0"
                    >
                      Verzenden
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
