import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { NavLink } from "react-router-dom";

const vettLogoBlack = new URL("/src/assets/vettLogoBlack.jpg", import.meta.url);

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className=" px-4 sm:px-6 lg:px-8 mx-auto max-w-[1500] h-[8vh]">
      <div class="flex items-center justify-between h-16 lg:h-20 bg-[#223]/30 rounded-b-2xl rounded-t-2xl shadow-lg px-4">
        <div className="text-black text-xl sm:text-2xl">
          <a href="#" className="font-bold">
            <span>
              <img
                src={vettLogoBlack}
                alt="vett logo"
                className=" h-16 lg:h-20 bg-[#223]/30 rounded-b-2xl rounded-t-2xl shadow-lg px-4"
              />
            </span>
          </a>
        </div>
        <ul class="text-gray-700 font-medium hidden lg:flex lg:items-center lg:justify-center lg:space-x-10 text-2xl">
          <li className="p-2">
            <NavLink
              to="/hero"
              activeClassName="selected"
              className="hover:text-white"
            >
              Home
            </NavLink>
          </li>
          <li className="p-2">
            <NavLink
              to="/about"
              activeClassName="text-black "
              className="hover:text-white"
            >
              Over ons
            </NavLink>
          </li>
          {/* <li className="p-2">
            <NavLink
              to="/products"
              activeClassName="text-black"
              className="hover:text-white"
            >
              Producten
            </NavLink>
          </li> */}
          <li className="p-2">
            <NavLink
              to="/contact"
              activeClassName="text-black"
              className="hover:text-white"
            >
              Contact
            </NavLink>
          </li>
        </ul>
        <div onClick={handleNav} className="block md:hidden text-white z-40">
          {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </div>
        <div
          className={
            nav
              ? "z-30 text-gray-300 fixed h-[50%] left-0 top-0 w-full bg-[#202121] ease-in-out duration-500"
              : "fixed left-[-100%]"
          }
        >
          <h1 className="text-3xl gray-primary-color m-4">VETT</h1>
          <ul className="p-8 text-4xl ml-20">
            <li className="p-2">
              <NavLink to="/hero" activeClassName="selected">
                Home
              </NavLink>
            </li>
            <li className="p-2">
              <NavLink to="/about" activeClassName="text-black">
                Over ons
              </NavLink>
            </li>
            {/* <li className="p-2">
              <NavLink to="/products" activeClassName="text-black">
                Producten
              </NavLink>
            </li> */}
            <li className="p-2">
              <NavLink to="/contact" activeClassName="text-black">
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
