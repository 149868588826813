import React from "react";
import { Link } from "react-router-dom";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
  AiOutlineClose,
  AiOutlineMenu,
} from "react-icons/ai";

const juneImage = new URL("/src/assets/junedetail.jpg", import.meta.url);
const vettLogoBlack = new URL("/src/assets/vettLogoBlack.jpg", import.meta.url);
const bathroom1 = new URL("/src/assets/junewindow.jpg", import.meta.url);

const Mainpage = () => {
  return (
    <>
      <img
        src={juneImage}
        alt="june baththube"
        className="fixed mt-[-75px] w-screen -z-10"
      />
      <div className=" pt-80 ml-80">
        <div className=" text-center">
          <h1 className=" text-9xl font-semibold text-stone-950">VETT</h1>
          <p className=" font-light text-5xl mt-5 text-stone-400 mb-8">
            Handmade <span className="text-stone-600">Bathrooms</span>{" "}
            Collection
          </p>
          <div>
            <Link
              to="/contact"
              title=""
              class="shadow-2xl mr-4 inline-flex items-center px-6 py-4 mt-8 font-semibold text-white bg-primary-color rounded-lg transition-all duration-200 hover:scale-110 drop-shadow-lg"
              role="button"
            >
              Contacteer ons
            </Link>
            {/* <a
              href="#"
              title=""
              class="inline-flex items-center px-6 py-4 mt-8 font-semibold text-white bg-secondary-color rounded-lg transition-all duration-200 hover:scale-110"
              role="button"
            >
              Onze producten
            </a> */}
          </div>

          {/* <div className=" text-left">
            <div class="flex flex-row justify-between text-stone-400 p-8 max-w-[250px] mx-auto">
              <a
                href="#"
                className="transition-all duration-200 hover:scale-110 cursor-pointer"
              >
                <AiFillLinkedin className="w-[70px] h-auto" />
              </a>
              <a
                href="#"
                className="transition-all duration-200 hover:scale-110 cursor-pointer"
              >
                <AiFillInstagram className="w-[70px] h-auto" />
              </a>
              <a
                href="#"
                className="transition-all duration-200 hover:scale-110 cursor-pointer"
              >
                <AiFillFacebook className="w-[70px] h-auto" />
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Mainpage;
